






































import { Component, Vue } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    BoxContainer,
    ExLoading
  }
})
export default class QuestionsLoading extends Vue {
  private quantityLoading = 4;
}














































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import QuestionItem from './components/QuestionItem/QuestionItem.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import CreditBox from '@/components/CreditBox/index.vue';
import Ranking from '@/components/Ranking/index.vue';

import Credits from '@/pages/Redaction/components/Credits/Credits.vue';

import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';
// import LastQuestionViewed from './components/LastQuestionViewed/index.vue';

import QuestionLoading from './QuestionLoading.vue';
import QuestionsLoading from './QuestionsLoading.vue';

import Arrow from '@/assets/icons/Arrow.vue';

import QuestionService from '@/services/Question/QuestionService';
import BookService from '@/services/Book/BookService';

import {
  PERMISSION_NAME_BY_DEGREE_ID,
  DEGREE_ID_BY_PERMISSION_NAME
} from '@/constant/PermissionBookByDegreeID';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';
// import { URL_BUTTON_CREDIT_QUESTION } from '@/constant/FreePlanUrls';

import Permission from '@/mixins/permission';
// import CreditsConsumer from '@/mixins/CreditsConsumer';

import { removeEmptyValues } from '@/share/Util/UsefulFunctions/UserfulFunctions';
import ActiveModal from '@/share/Util/ActiveModal';

import { Topic } from './interface';
import { Book } from '@/globalInterfaces/Book';
import { GetQuestions } from '@/services/Question/IQuestion';
import { Question as IQuestion } from '@/globalInterfaces/Questions';

const FIRST_PAGE = 0;
const LIMIT_QUESTION = 10;
const ALL_SELECT = 'all';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    Arrow,
    QuestionItem,
    FeedbackUser,
    Footer,
    QuestionLoading,
    QuestionsLoading,
    ContainerFluid,
    CreditBox,
    Credits,
    RealtimeUsers,
    Ranking
    // LastQuestionViewed,
  },
  filters: {
    formatNumber(value: number) {
      return value < 10 ? `0${value}` : `${value}`;
    }
  }
})
export default class Question extends Mixins(Permission) {
  private isLoadingTopics = false;
  private isLoadingQuestion = false;
  private isLoadingMoreQuestion = false;
  private noItems = false;
  private showAnswer = false;

  // private URL_BUTTON_CREDIT = URL_BUTTON_CREDIT_QUESTION;

  private allBooks: Array<Book> = [];
  private topics: Array<Topic> = [];
  private questions: Array<IQuestion> = [];

  private disciplineID: number | string | null = ALL_SELECT;
  private topicID: number | string | null = null;
  private page = FIRST_PAGE;
  private totalPage: number | null = null;
  private excludeDegrees: Array<number> = [];

  private QuestionService = new QuestionService();
  private BookService = new BookService();

  private ActiveModal = new ActiveModal();

  created() {
    this.getBooks();
    this.getQuestions();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get maxPage() {
    if (this.totalPage) return false;

    return this.page >= Number(this.totalPage);
  }

  get disabledButton() {
    return this.maxPage || this.isLoadingMoreQuestion || this.noItems || this.isLoadingQuestion;
  }

  get disabledSelectTopic() {
    return !this.disciplineID || this.disciplineID === ALL_SELECT;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get setClassCol() {
    return 'col-xl-9 col-md-8 col-12 order-2 order-md-1';
  }

  // @Watch('getTotalCreditsConsumed', {
  //   immediate: true,
  // })
  // activeModalConsumerCredits() {
  //   if (this.getTotalCreditsConsumed <= this.NOT_CREDIT_VALUE && this.isPlanFree) {
  //     this.ActiveModal.activeModal('ModalConsumerCredits');
  //   }
  // }

  async getBooks() {
    try {
      const getAllBooks = await this.BookService.books(
        undefined,
        this.$store.getters.profile?.institution?.id
      );

      this.allBooks = this.filterBookPermission(getAllBooks);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar os livros.',
        status: 'error'
      });
      console.error(error);
    }
  }

  filterBookPermission(books: Array<Book>) {
    return books.filter((book: Book) => this.can(PERMISSION_NAME_BY_DEGREE_ID[book?.degreeID]));
  }

  setExcludeDegrees() {
    const excludeDegrees = [];

    if (!this.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL)) {
      excludeDegrees.push(DEGREE_ID_BY_PERMISSION_NAME[LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL]);
    }

    if (!this.can(LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II)) {
      excludeDegrees.push(
        DEGREE_ID_BY_PERMISSION_NAME[LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II]
      );
    }

    this.excludeDegrees = excludeDegrees;
  }

  @Watch('$route.query', {
    immediate: true
  })
  async setSelect() {
    this.disciplineID = Number(this.$route.query.disciplineID) || ALL_SELECT;
    this.topicID = Number(this.$route.query.topicID) || null;

    await this.getBookTopics(Number(this.disciplineID));
  }

  @Watch('disciplineID')
  @Watch('topicID')
  async getQuestions() {
    try {
      this.isLoadingQuestion = true;

      this.setExcludeDegrees();

      const data = this.setDataGetQuestion();

      const response = await this.QuestionService.getQuestion(data);

      if (response.questions.length) {
        this.questions = this.treatmentQuestion(response.questions);
        this.totalPage = Math.round(Number(response.totalQuestions) / LIMIT_QUESTION);
        this.page = FIRST_PAGE;

        this.noItems = false;
      } else this.noItems = true;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as questões.',
        status: 'error'
      });
    } finally {
      this.isLoadingQuestion = false;
    }
  }

  setDataGetQuestion(page = FIRST_PAGE, limit = LIMIT_QUESTION) {
    const data: GetQuestions = removeEmptyValues({
      page,
      limit,
      excludeDegrees: this.excludeDegrees
    });

    if (this.disciplineID && this.disciplineID !== ALL_SELECT) {
      data.disciplineID = [Number(this.disciplineID)];
    }

    if (this.topicID) {
      data.topicID = [Number(this.topicID)];
    }

    return data;
  }

  async getBookTopics(idBook: number) {
    try {
      this.isLoadingTopics = true;

      if (!idBook) return;

      const topics = await this.BookService.getTopicsBook(idBook);

      if (topics.length) {
        this.topics = topics;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar a lista de tópicos.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoadingTopics = false;
    }
  }

  async getMoreQuestions() {
    try {
      this.isLoadingMoreQuestion = true;

      if (this.maxPage) return;

      const nextPage = this.page + 1;

      const data = this.setDataGetQuestion(nextPage);

      const response = await this.QuestionService.getQuestion(data);

      if (response.questions.length) {
        response.questions = this.treatmentQuestion(response.questions);
        this.totalPage = Math.round(Number(response.totalQuestions) / LIMIT_QUESTION);
        this.page += 1;

        this.questions = [...this.questions, ...response.questions];
        this.noItems = false;
      } else this.noItems = true;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as questões.',
        status: 'error'
      });
    } finally {
      this.isLoadingMoreQuestion = false;
    }
  }

  setChangeSelectBook(e: any) {
    if (!e.target.value) {
      this.disciplineID = null;
      this.topics = [];
    } else if (e.target.value === ALL_SELECT) {
      this.disciplineID = ALL_SELECT;
    } else {
      this.disciplineID = Number(e.target.value);
      this.getBookTopics(this.disciplineID);
    }

    this.topicID = null;
  }

  setChangeSelectTopic(e: any) {
    this.topicID = Number(e.target.value) || null;
  }

  treatmentQuestion(questions: IQuestion[]) {
    if (!questions.length) return [];

    return questions.map((question: IQuestion) => {
      let content = question.contentfix.replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g, '');

      content = content.replace(
        /<br[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>|(<p>[^a-zA-ZÀ-ü0-9]*<\/p>)/g,
        ''
      );

      return {
        ...question,
        content
      };
    });
  }

  handleRedirectQuestion(questionId: number) {
    this.$router.push({
      name: 'AnswerQuestion',
      params: {
        id: String(questionId)
      }
    });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([{ title: 'Banco de Questões', to: null }]);
  }
}
